<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-dept type="search" :isFirstValue="false" label="부서" name="deptCd" v-model="searchParam.deptCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-field label="대상자" name="userId" v-model="searchParam.userId" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 병원 -->
            <c-select
              :comboItems="comboItems"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="hospitalId"
              label="병원"
              v-model="searchParam.hospitalId"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :range="true"
              label="검진 기간"
              defaultStart="-6M"
              defaultEnd="0M"
              name="checkupPeriod"
              v-model="searchParam.checkupPeriod"
            />
          </div>
        </template>
      </c-search-box>
      <c-table
        ref="table"
        title="개인별 검진결과 현황 목록"
        tableId="table"
        :merge="grid.merge"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        rowKey="heaCheckupResultId;"
        :columnSetting="false"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'checkupDate'">
            {{ props.row['checkupDate']}}
            <q-icon
              name="launch"
              size="xs"
              color="blue-6"
              class="cursor-pointer"
              label=""
              @click.stop="linkClick(props.row, col)" />
          </template>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: 'check-up-implementation',
    data() {
      return {
        grid: {
          merge: [
            { index: 0, colName: 'userId' },
            { index: 1, colName: 'userId' },
            { index: 2, colName: 'userId' },
          ],
          columns: [
            {
              name: 'deptName',
              field: 'deptName',
              label: '부서',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
            },
            {
              name: 'empNo',
              field: 'empNo',
              label: '사번',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '대상자',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
            },
            {
              name: 'hospitalName',
              field: 'hospitalName',
              label: '병원',
              style: 'width:130px',
              align: 'center',
              sortable: true,
            },
            {
              name: 'checkupName',
              field: 'checkupName',
              label: '제목',
              style: 'width:400px',
              align: 'left',
              sortable: true,
            },
            {
              name: 'checkupDate',
              field: 'checkupDate',
              label: '검진일',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
              type: 'link'
            },
          ],
          data: [],
        },
        searchParam: {
          plantCd: '',
          deptCd: '',
          userId: '',
          checkupPeriod: [],
          hospitalId: null,
        },
        editable: true,
        popupOptions: {
          target: null,
          title: '',
          visible: false,
          isFull: true,
          top: '',
          param: {},
          closeCallback: null,
        },
        listUrl: '',
        saveUrl: '',
        isSave: false,
        comboItems:[],
      };
    },
    computed: {
      isHeaDept() { 
        return this.$_.indexOf(this.$store.getters.auths, 'SAGI000022') > -1 
      },
    },  
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.hea.checkup.individual.list.url;

        if (!this.isHeaDept) {
          this.searchParam.userId = this.$store.getters.user.userId
        }

        this.getList();
        this.setHospital();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
      setHospital() {
        this.$http.url = selectConfig.hea.hospital.list.url;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y',
        };
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.comboItems.push({
              code: _item.hospitalId,
              codeName: _item.hospitalName,
              typeFlag: _item.typeFlag,
            })
          })
        },);
      },
      linkClick(result) {
        this.popupOptions.target = () => import(`${"./checkUpIndividualStatusDetail.vue"}`);
        this.popupOptions.title = result.userName + '의 건강검진 결과 상세';
        this.popupOptions.width = '70%';
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          heaCheckupPlanId: result ? result.heaCheckupPlanId : '',
          heaCheckupResultId: result ? result.heaCheckupResultId : '',
          userId: result ? result.userId : '',
        };
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      },
      closePopup() {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        this.getList();
      },
    }
  };
  </script>